html {
  font: normal normal normal 16px/21px Segoe UI;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

p {
  text-align: left;
  font: normal normal normal 16px/21px Segoe UI;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.iconMargin {
  margin: 5px;
}

p span {
  font: normal normal 300 18px/24px Segoe UI;
  letter-spacing: 0px;
  color: #CCCCCC;
  opacity: 1;
}

h2 {
  font: normal normal 600 20px/27px Segoe UI;
  letter-spacing: 0px;
  color: #10B0BF;
  opacity: 1;
  font-size: 1.2em !important;
}

h3 {
  font-weight: 200 !important;
  color: #fff;
  font-size: 1em !important;

}


@media(min-width:768px) {
  h3 {
    font-weight: 200 !important;
    color: #fff;
    font-size: 1.5em !important;

  }
}

.selectYear {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5em;
}

.selectYear option {
  color: #000;
}

.multiselect-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E2E2E2;
  border-radius: 3px;
}